import { z } from 'zod'

const LocationSchema = z.object({
  address: z.string().optional(),
  city: z.string().optional(),
  region: z.string().optional(),
  countryCode: z.string().optional(),
  postalCode: z.string().optional(),
})

const EducationSchema = z.object({
  institution: z.string(),
  area: z.string().optional(),
  studyType: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  url: z.string().url().optional(),
  score: z.string().optional(),
})

const WorkSchema = z.object({
  name: z.string(),
  location: z.string().optional(),
  description: z.string().optional(),
  position: z.string(),
  url: z.string().url().optional(),
  logo: z.string().url().optional().nullable(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  summary: z.string().optional(),
  highlights: z.array(z.string()).optional(),
  keywords: z.array(z.string()).optional(),
})

const CertificateSchema = z.object({
  name: z.string(),
  issuer: z.string(),
  url: z.string().url().optional(),
  date: z.string().optional(),
})

const SkillSchema = z.object({
  name: z.string(),
  level: z.string().optional(),
  keywords: z.array(z.string()).optional(),
})

const Profile = z.object({
  url: z.string().url(),
})

const Language = z.object({
  language: z.string(),
  fluency: z.string(),
})

const Basics = z.object({
  name: z.string(),
  label: z.string().optional(),
  image: z.string().url().optional(),
  email: z.string().email().optional(),
  phone: z.string().optional(),
  summary: z.string().optional(),
  location: LocationSchema,
  profiles: z.array(Profile).optional().default([]),
  url: z.string().url().optional(),
})

const ProjectSchema = z.object({
  name: z.string(),
  summary: z.string().optional(),
  highlights: z.array(z.string()).optional(),
  url: z.string().url().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  role: z.string().optional(),
  entity: z.string().optional(),
  type: z.string().optional(),
  logo: z.string().url().optional(),
  keywords: z.array(z.string()).optional(),
})

export const ResumeSchema = z.object({
  basics: Basics.default({
    name: 'John Doe',
    location: {},
  }),
  work: z.array(WorkSchema).optional().default([]),
  education: z.array(EducationSchema).optional().default([]),
  certificates: z.array(CertificateSchema).optional().default([]),
  skills: z.array(SkillSchema).optional().default([]),
  languages: z.array(Language).optional().default([]),
  projects: z.array(ProjectSchema).optional().default([]),
})

export type Resume = z.infer<typeof ResumeSchema>
export type Location = z.infer<typeof LocationSchema>
export type Education = z.infer<typeof EducationSchema>
export type Work = z.infer<typeof WorkSchema>
export type Certificate = z.infer<typeof CertificateSchema>
export type Skill = z.infer<typeof SkillSchema>
export type Profile = z.infer<typeof Profile>
export type Language = z.infer<typeof Language>
export type Basics = z.infer<typeof Basics>
export type Project = z.infer<typeof ProjectSchema>
