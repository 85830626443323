import { Cross2Icon, PlusIcon } from '@radix-ui/react-icons'
import { useFieldArray, useFormContext } from 'react-hook-form'

import InputField from '@/components/form/InputField'
import { Button } from '@/components/ui/button'
import { type Resume } from '@/schema/ResumeSchema'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip'

export default function ProfilesField() {
  const form = useFormContext<Resume>()

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: 'basics.profiles',
  })

  return (
    <section>
      <header className="flex items-center justify-between">
        <h3>Profiles</h3>

        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              size="icon"
              variant="ghost"
              onClick={() => append({ url: '' })}
            >
              <PlusIcon />
            </Button>
          </TooltipTrigger>

          <TooltipContent>Add Profile</TooltipContent>
        </Tooltip>
      </header>

      <div className="space-y-4">
        {fields.map((field, index) => (
          <div key={field.id} className="flex items-end gap-2">
            <InputField
              form={form}
              name={`basics.profiles.${index}.url`}
              label="URL"
              placeholder="https://linkedin.com/in/..."
              className="flex-grow"
            />
            <Button
              size="icon"
              variant="ghost"
              className="hover:text-destructive hover:bg-destructive/5"
              onClick={() => remove(index)}
            >
              <Cross2Icon />
            </Button>
          </div>
        ))}
      </div>
    </section>
  )
}
