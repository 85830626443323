import { zodResolver } from '@hookform/resolvers/zod'
import { PropsWithChildren } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
  BackpackIcon,
  BookmarkIcon,
  FileIcon,
  HeartIcon,
  HobbyKnifeIcon,
  Link2Icon,
  PersonIcon,
  RocketIcon,
  RulerSquareIcon,
  SpeakerLoudIcon,
  StarIcon,
  TargetIcon,
} from '@radix-ui/react-icons'

import { type Resume, ResumeSchema } from '@/schema/ResumeSchema'
import { useResume } from '@/context/resume-context'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'

// Sections
import BasicsSection from './sections/BasicsSection'
import EducationSection from './sections/EducationSection'
import WorkSection from './sections/WorkSection'
import CertificatesSection from './sections/CertificatesSection'
import SkillsSection from './sections/SkillsSection'
import LanguagesSection from './sections/LanguagesSection'
import ProjectsSection from './sections/ProjectsSection'

const FormSection = ({
  title,
  icon,
  children,
}: PropsWithChildren<{ title: string; icon: JSX.Element }>) => (
  <AccordionItem value={title} className="border-none">
    <AccordionTrigger
      className="bg-white rounded-lg shadow px-4"
      headerClassName="sticky top-0 z-30"
    >
      <header className="flex items-center gap-2">
        {icon}
        <h2>{title}</h2>
      </header>
    </AccordionTrigger>

    <AccordionContent className="p-4 border border-t-0 rounded-b-lg">
      {children}
    </AccordionContent>
  </AccordionItem>
)

export default function ResumeForm() {
  const { resume, setResume } = useResume()

  const form = useForm<Resume>({
    defaultValues: resume.resume,
    resolver: zodResolver(ResumeSchema),
  })

  return (
    <FormProvider {...form}>
      <form
        onChange={() => {
          setResume((old) => ({ ...old, resume: form.getValues() }))
        }}
      >
        <Accordion type="multiple" className="space-y-2">
          <FormSection title="Basics" icon={<PersonIcon />}>
            <BasicsSection />
          </FormSection>

          <FormSection title="Work" icon={<BackpackIcon />}>
            <WorkSection />
          </FormSection>

          <FormSection title="Education" icon={<RulerSquareIcon />}>
            <EducationSection />
          </FormSection>

          <FormSection title="Certificates" icon={<BookmarkIcon />}>
            <CertificatesSection />
          </FormSection>

          <FormSection title="Projects" icon={<RocketIcon />}>
            <ProjectsSection />
          </FormSection>

          <FormSection title="Publications" icon={<FileIcon />}>
            {/* TODO */}
          </FormSection>

          <FormSection title="Skills" icon={<HobbyKnifeIcon />}>
            <SkillsSection />
          </FormSection>

          <FormSection title="Languages" icon={<SpeakerLoudIcon />}>
            <LanguagesSection />
          </FormSection>

          <FormSection title="Volunteer" icon={<HeartIcon />}>
            {/* TODO */}
          </FormSection>

          <FormSection title="Interests" icon={<StarIcon />}>
            {/* TODO */}
          </FormSection>

          <FormSection title="References" icon={<Link2Icon />}>
            {/* TODO */}
          </FormSection>

          <FormSection title="Awards" icon={<TargetIcon />}>
            {/* TODO */}
          </FormSection>
        </Accordion>
      </form>
    </FormProvider>
  )
}
