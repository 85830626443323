import { PlusIcon } from '@radix-ui/react-icons'
import { useFieldArray, useFormContext } from 'react-hook-form'

import InputField from '@/components/form/InputField'
import { Button } from '@/components/ui/button'
import { Resume } from '@/schema/ResumeSchema'
import RemoveItemButton from '../RemoveItemButton'

export default function LanguagesSection() {
  const form = useFormContext<Resume>()
  const { fields, append, remove } = useFieldArray<Resume>({
    name: 'languages',
  })

  return (
    <section>
      <header className="flex items-center justify-end mb-4">
        <Button
          variant="outline"
          icon={PlusIcon}
          onClick={() => append({ language: '', fluency: '' })}
        >
          Add Language
        </Button>
      </header>

      <div className="space-y-4">
        {fields.map((field, index) => (
          <div key={field.id} className="flex items-end gap-2">
            <InputField
              form={form}
              name={`languages.${index}.language`}
              label="Language"
              className="flex-1"
            />

            <InputField
              form={form}
              name={`languages.${index}.fluency`}
              label="Fluency"
              className="flex-1"
            />

            <RemoveItemButton onClick={() => remove(index)} />
          </div>
        ))}
      </div>
    </section>
  )
}
