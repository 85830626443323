import { ArrowLeftIcon } from '@radix-ui/react-icons'
import { useNavigate } from '@tanstack/react-router'
import { useState } from 'react'
import Dropzone from 'react-dropzone'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { importResumeFromLinkedIn } from './linkedin'
import { createResume } from '@/repository'
import { cn } from '@/lib/utils'

export default function ImportResumeDialog() {
  const [importType, setImportType] = useState<'linkedin' | undefined>()
  const navigate = useNavigate()

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Create Resume</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          {importType ? (
            <DialogTitle className="flex items-center gap-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => {
                  setImportType(undefined)
                }}
              >
                <ArrowLeftIcon />
              </Button>

              <span>Import from LinkedIn</span>
            </DialogTitle>
          ) : (
            <DialogTitle>Create a new resume</DialogTitle>
          )}
        </DialogHeader>

        {!importType && (
          <div className="flex flex-col gap-4 items-stretch w-72 mx-auto mt-4">
            <Button
              variant="outline"
              size="lg"
              onClick={async () => {
                const resume = await createResume()
                navigate({
                  to: `/resumes/$resumeId`,
                  params: { resumeId: resume.id },
                })
              }}
            >
              Start from scratch
            </Button>

            <Button
              variant="outline"
              size="lg"
              onClick={() => setImportType('linkedin')}
            >
              <img src="/icons/linkedin.png" className="size-6 mr-4" />
              Import from LinkedIn
            </Button>
          </div>
        )}

        {importType === 'linkedin' && (
          <Dropzone
            accept={{ 'application/zip': ['.zip'] }}
            onDrop={async (files) => {
              const file = files[0]
              if (!file) return

              try {
                const resume = await importResumeFromLinkedIn(file)
                const newResume = await createResume({
                  resume,
                })

                navigate({
                  to: `/resumes/$resumeId`,
                  params: { resumeId: newResume.id },
                })
              } catch (error) {
                console.error('Error importing resume:', error)
              }
            }}
          >
            {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
              <section
                className={cn(
                  'border-2 border-dashed border-primary/20 hover:border-primary rounded-md p-4 text-center bg-secondary transition-colors cursor-pointer',
                  {
                    'border-primary': isDragAccept,
                    'border-red-500': isDragReject,
                  },
                )}
              >
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  Click or drop
                  <br />
                  your LinkedIn data export
                  <small className="block mt-2 text-xs text-gray-500">
                    .zip format
                  </small>
                </div>
              </section>
            )}
          </Dropzone>
        )}
      </DialogContent>
    </Dialog>
  )
}
