import JSZip from 'jszip'
import Papa from 'papaparse'

import { LinkedIn, linkedInSchema } from './schema'
import { Resume, ResumeSchema } from '@/schema/ResumeSchema'

/**
 * Parser for cases when we receive an array like structure f.e. in the LinkedIn Profile.csv import
 * @param csvEntry array-like entry such as [TAG:https://some.link,TAG:https://someother.link]
 * @returns
 */
export const parseArrayLikeCSVEntry = (csvEntry: string) =>
  csvEntry
    .replace(/^\[/, '')
    .replace(/]$/, '')
    .split(',')
    .map((entry) => entry.replace(/.*?:/, ''))

export const parseCSV = async (string: string) => {
  return new Promise<Record<string, unknown>[]>((resolve, reject) => {
    Papa.parse(string, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        resolve(results.data as Record<string, unknown>[])
      },
      error: (error: Error) => {
        reject(error)
      },
    })
  })
}

const parseLinkedinData = async (file: File): Promise<LinkedIn> => {
  const data = await JSZip.loadAsync(file)

  if (Object.keys(data.files).length === 0) {
    throw new Error(
      'ParserError: There were no files found inside the zip archive.',
    )
  }

  const result: Record<string, unknown> = {}

  for (const [name, file] of Object.entries(data.files)) {
    for (const key of Object.keys(linkedInSchema.shape)) {
      if (name.includes(key)) {
        const content = await file.async('text')
        result[key] = await parseCSV(content)
      }
    }
  }

  return linkedInSchema.parse(result)
}

const convertLinkedInToResume = (data: LinkedIn): Resume => {
  const result = ResumeSchema.parse({})

  // Profile
  if (data.Profile && data.Profile.length > 0) {
    const profile = data.Profile[0]

    result.basics.name = `${profile['First Name']} ${profile['Last Name']}`
    result.basics.location.address = profile['Geo Location']
    result.basics.summary = profile.Summary

    result.basics.url = parseArrayLikeCSVEntry(profile.Websites)[0]

    const twitterHandle = parseArrayLikeCSVEntry(profile['Twitter Handles'])[0]
    if (twitterHandle) {
      result.basics.profiles.push({
        url: `https://twitter.com/${twitterHandle}`,
      })
    }
  }

  // Email Addresses
  if (data['Email Addresses'] && data['Email Addresses'].length > 0) {
    const email = data['Email Addresses'][0]

    result.basics.email = email['Email Address']
  }

  // Positions
  if (data.Positions && data.Positions.length > 0) {
    for (const position of data.Positions) {
      const description = position.Description || ''

      let summary: string | undefined
      const highlights = description.includes(' - ')
        ? description.split(' - ')
        : description.split(' * ')

      if (description[0] !== '-' && description[0] !== '*') {
        summary = highlights.shift()
      } else {
        highlights[0] = highlights[0].replace(/^[*-]\s*/, '')
      }

      result.work.push({
        name: position['Company Name'],
        position: position.Title,
        location: position.Location,
        summary,
        highlights,
        startDate: position['Started On'],
        endDate: position['Finished On'],
      })
    }
  }

  // Education
  if (data.Education && data.Education.length > 0) {
    for (const education of data.Education) {
      result.education.push({
        institution: education['School Name'],
        studyType: education['Degree Name'],
        area: education.Activities,
        startDate: education['Start Date'],
        endDate: education['End Date'],
      })
    }
  }

  // Skills
  if (data.Skills && data.Skills.length > 0) {
    for (const skill of data.Skills) {
      result.skills.push({
        name: skill.Name,
      })
    }
  }

  // Languages
  if (data.Languages && data.Languages.length > 0) {
    for (const language of data.Languages) {
      result.languages.push({
        language: language.Name,
        fluency: language.Proficiency?.replace(/\s*proficiency/, '') ?? 'Basic',
      })
    }
  }

  // Certifications
  if (data.Certifications && data.Certifications.length > 0) {
    for (const certification of data.Certifications) {
      result.certificates.push({
        name: certification.Name,
        issuer: certification.Authority,
        url: certification.Url,
        date: certification['Started On'],
      })
    }
  }

  // Projects
  if (data.Projects && data.Projects.length > 0) {
    for (const project of data.Projects) {
      result.projects.push({
        name: project.Title,
        summary: project.Description,
        url: project.Url,
        startDate: project['Started On'],
        endDate: project['Finished On'],
      })
    }
  }

  return ResumeSchema.parse(result)
}

export const importResumeFromLinkedIn = async (file: File): Promise<Resume> => {
  const linkedInData = await parseLinkedinData(file)
  console.log('LinkedIn data:', linkedInData)
  return convertLinkedInToResume(linkedInData)
}
