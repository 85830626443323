import { useFormContext } from 'react-hook-form'

import InputField from '@/components/form/InputField'
import TextareaField from '@/components/form/TextareaField'
import { Resume } from '@/schema/ResumeSchema'
import ProfilesField from './ProfilesField'
import ImageField from '@/components/form/ImageField'

export default function BasicsSection() {
  const form = useFormContext<Resume>()

  return (
    <section>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2 lg:gap-4">
        <InputField form={form} name="basics.name" label="Name" />

        <InputField
          form={form}
          name="basics.label"
          label="Label"
          placeholder="e.g. Software lead"
        />

        <TextareaField
          form={form}
          name="basics.summary"
          label="Summary"
          className="md:col-span-2"
          rows={3}
        />

        <ImageField
          form={form}
          name="basics.image"
          label="Profile picture"
          className="md:col-span-2"
        />

        <InputField
          form={form}
          name="basics.email"
          label="Email"
          type="email"
        />

        <InputField form={form} name="basics.phone" label="Phone" type="tel" />

        <InputField form={form} name="basics.url" label="Website" type="url" />
      </div>

      <h3 className="font-medium mb-4 mt-8">Location</h3>

      <div className="grid grid-cols-1 md:grid-cols-12 gap-2 lg:gap-4">
        <InputField
          form={form}
          name="basics.location.address"
          label="Address"
          className="md:col-span-12"
        />

        <InputField
          form={form}
          name="basics.location.city"
          label="City"
          className="md:col-span-6"
        />

        <InputField
          form={form}
          name="basics.location.region"
          label="Region"
          className="md:col-span-6"
        />

        <InputField
          form={form}
          name="basics.location.postalCode"
          label="Postal Code"
          className="md:col-span-4"
        />

        <InputField
          form={form}
          name="basics.location.countryCode"
          label="Country"
          className="md:col-span-8"
        />
      </div>

      <ProfilesField />
    </section>
  )
}
