import { TrashIcon } from '@radix-ui/react-icons'

import { Button } from '@/components/ui/button'
import { cn } from '@/lib/utils'

export default function RemoveItemButton({
  className,
  onClick,
}: Pick<React.ComponentProps<typeof Button>, 'onClick' | 'className'>) {
  return (
    <Button
      size="icon"
      variant="ghost"
      className={cn(
        'hover:text-destructive hover:bg-destructive/5',
        className,
      )}
      onClick={onClick}
    >
      <TrashIcon />
    </Button>
  )
}
