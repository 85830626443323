import { PlusIcon } from '@radix-ui/react-icons'
import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form'

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Resume } from '@/schema/ResumeSchema'
import InputField from '@/components/form/InputField'
import RemoveItemButton from '../RemoveItemButton'
import NoTitleAccordion from '../NoTitleAccordion'
import ArrayField from '../ArrayField'

interface SkillFieldProps {
  index: number
  remove: UseFieldArrayRemove
}

interface KeywordsProps {
  form: UseFormReturn<Resume>
  index: number
}

const KeywordsField = ({ index, form }: KeywordsProps) => {
  const { fields, append, remove } = useFieldArray({
    name: `skills.${index}.keywords`,
  })

  return (
    <div>
      <header className="flex items-center justify-between mb-2">
        <h3>Keywords</h3>

        <Button variant="outline" onClick={() => append('')} icon={PlusIcon}>
          Add Keyword
        </Button>
      </header>

      <div className="space-y-2">
        {fields.map((field, kIndex) => (
          <div key={field.id} className="relative flex items-end gap-2">
            <InputField
              form={form}
              name={`skills.${index}.keywords.${kIndex}`}
              className="flex-grow"
            />

            <RemoveItemButton onClick={() => remove(kIndex)} />
          </div>
        ))}
      </div>
    </div>
  )
}

const SkillField = ({ index, remove }: SkillFieldProps) => {
  const form = useFormContext<Resume>()

  const triggerTitle = form.watch(`skills.${index}.name`) || (
    <NoTitleAccordion>New Skill</NoTitleAccordion>
  )

  return (
    <AccordionItem value={`skills.${index}`} className="border-none">
      <AccordionTrigger className="group gap-6 bg-white py-2 px-4 rounded-lg border">
        <header className="flex flex-grow items-center justify-between gap-2 font-bold">
          {triggerTitle}
          <RemoveItemButton
            onClick={() => remove(index)}
            className="opacity-0 group-hover:opacity-100 transition-[opacity,color,text-color]"
          />
        </header>
      </AccordionTrigger>

      <AccordionContent className="mt-4 mb-8 p-0">
        <div className="group relative grid grid-cols-1 gap-2">
          <InputField form={form} name={`skills.${index}.name`} label="Name" />

          <KeywordsField index={index} form={form} />
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

export default function SkillsSection() {
  const { fields, append, remove } = useFieldArray<Resume>({
    name: 'skills',
  })

  return (
    <ArrayField
      addLabel="Skill"
      onAddClick={() => {
        append({ name: '' })
        return `skills.${fields.length}`
      }}
    >
      {fields.map((field, index) => (
        <SkillField key={field.id} index={index} remove={remove} />
      ))}
    </ArrayField>
  )
}
