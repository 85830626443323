/**
 * Beautify URL removing http(s) and www from the beginning
 * and trailing slash from the end
 * @param url
 * @returns
 * @example
 * beautifyUrl('https://www.google.com/') // google.com
 */
export const beautifyUrl = (url: string): string => {
  const parsedUrl = new URL(url)

  return parsedUrl.hostname + parsedUrl.pathname.replace(/\/$/, '')
}

const LOGOS_BY_URL: Record<string, string> = {
  linkedin: 'linkedin.png',
  github: 'github.png',
}

export const getLogoByURL = (url: string): string | undefined => {
  const key = Object.keys(LOGOS_BY_URL).find((key) => url.includes(key))
  return key ? `/icons/${LOGOS_BY_URL[key]}` : undefined
}
