import { PlusIcon } from '@radix-ui/react-icons'
import { PropsWithChildren, useState } from 'react'

import { Accordion } from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'

interface Props extends PropsWithChildren {
  addLabel: string
  onAddClick: () => string
}

export default function ArrayField({ addLabel, children, onAddClick }: Props) {
  const [accordionState, setAccordionState] = useState<string[]>([])

  return (
    <section>
      <header className="flex items-center justify-end mb-4">
        <Button
          variant="outline"
          icon={PlusIcon}
          onClick={() => {
            const accordionItemId = onAddClick()
            setTimeout(
              () => setAccordionState([...accordionState, accordionItemId]),
              0,
            )
          }}
        >
          Add {addLabel}
        </Button>
      </header>

      <Accordion
        type="multiple"
        className="space-y-4"
        value={accordionState}
        onValueChange={setAccordionState}
      >
        {children}
      </Accordion>
    </section>
  )
}
