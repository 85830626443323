import { createFileRoute } from '@tanstack/react-router'

import { fetchResume } from '@/repository'
import Builder from '@/components/resume-form/Builder'
import PdfViewer from '@/viewer/PdfViewer'
import { ResumeProvider } from '@/context/resume-context'
import { checkAuthentication } from '@/lib/authentication'
// Needed to load the templates
import '@/templates'
import ResumeToolbar from '@/viewer/ResumeToolbar'

export const Route = createFileRoute('/resumes/$resumeId')({
  component: Resume,
  beforeLoad: checkAuthentication,
  loader: ({ params: { resumeId } }) => fetchResume(resumeId),
})

function Resume() {
  const { resumeId } = Route.useParams()

  return (
    <ResumeProvider resumeId={resumeId}>
      <div className="flex h-screen">
        <div className="flex flex-col flex-grow">
          <ResumeToolbar />
          <div className="px-12 bg-slate-50 flex-grow overflow-y-scroll overflow-x-auto">
            <Builder />
          </div>
        </div>

        <div className="w-[500px] h-full overflow-y-auto antialiased">
          <PdfViewer />
        </div>
      </div>
    </ResumeProvider>
  )
}
