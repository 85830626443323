import {
  useFieldArray,
  UseFieldArrayRemove,
  useFormContext,
} from 'react-hook-form'

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import { Resume } from '@/schema/ResumeSchema'
import InputField from '@/components/form/InputField'
import RemoveItemButton from '../RemoveItemButton'
import ArrayField from '../ArrayField'
import NoTitleAccordion from '../NoTitleAccordion'

interface EducationFieldProps {
  index: number
  remove: UseFieldArrayRemove
}

const EducationField = ({ index, remove }: EducationFieldProps) => {
  const form = useFormContext<Resume>()

  const triggerTitle = [
    form.watch(`education.${index}.studyType`),
    form.watch(`education.${index}.institution`),
  ]
    .filter((v) => v !== '')
    .join(' @ ') || <NoTitleAccordion>New Education</NoTitleAccordion>

  return (
    <AccordionItem value={`education.${index}`} className="border-none">
      <AccordionTrigger className="group gap-6 bg-white py-2 px-4 rounded-lg border">
        <header className="flex flex-grow items-center justify-between gap-2 font-bold">
          {triggerTitle}
          <RemoveItemButton
            onClick={() => remove(index)}
            className="opacity-0 group-hover:opacity-100 transition-opacity"
          />
        </header>
      </AccordionTrigger>

      <AccordionContent className="mt-4 mb-8 p-0">
        <div className="group relative grid grid-cols-1 md:grid-cols-2 gap-2">
          <InputField
            form={form}
            name={`education.${index}.institution`}
            label="Institution"
            className="md:col-span-2"
          />

          <InputField
            form={form}
            name={`education.${index}.area`}
            label="Area"
            className="md:col-span-2"
          />

          <InputField
            form={form}
            name={`education.${index}.studyType`}
            label="Study Type"
            className="md:col-span-2"
          />

          <InputField
            form={form}
            name={`education.${index}.startDate`}
            label="Start Date"
          />

          <InputField
            form={form}
            name={`education.${index}.endDate`}
            label="End Date"
          />

          <InputField
            form={form}
            name={`education.${index}.url`}
            label="Website"
            className="md:col-span-2"
          />

          <InputField
            form={form}
            name={`education.${index}.score`}
            label="Score"
            className="md:col-span-2"
          />
        </div>
      </AccordionContent>
    </AccordionItem>
  )
}

export default function EducationSection() {
  const { fields, append, remove } = useFieldArray<Resume>({
    name: 'education',
  })

  return (
    <ArrayField
      addLabel="Education"
      onAddClick={() => {
        append({ institution: '' })
        return `education.${fields.length}`
      }}
    >
      {fields.map((field, index) => (
        <EducationField key={field.id} index={index} remove={remove} />
      ))}
    </ArrayField>
  )
}
