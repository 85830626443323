import { ImageIcon } from '@radix-ui/react-icons'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { useSession } from '@/hooks/use-session'
import { getFilePublicUrl, listFiles } from '@/repository'

interface Props {
  onSelect?: (url: string) => void
}

const FileManager: React.FC<Props> = ({ onSelect }) => {
  const [open, setOpen] = useState(false)
  const { session } = useSession()

  const userId = session!.user.id

  const files = useQuery({
    queryKey: ['files'],
    queryFn: async () => listFiles(userId),
  })

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" icon={ImageIcon}>
          Your files
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[700px]">
        <DialogHeader>
          <DialogTitle>Files manager</DialogTitle>
          <DialogDescription>Pick one of your existing files</DialogDescription>
        </DialogHeader>
        <div className="grid grid-cols-4 gap-4 py-4 content-center">
          {files.data?.map((file) => (
            <div
              key={file.name}
              className="border rounded-sm p-1 hover:border-primary transition-colors cursor-pointer"
              onClick={() => {
                onSelect && onSelect(getFilePublicUrl(`${userId}/${file.name}`))
                setOpen(false)
              }}
            >
              <img
                src={getFilePublicUrl(`${userId}/${file.name}`)}
                alt={file.name}
                className="object-cover max-h-20 mx-auto"
              />
              <div className="text-sm text-muted-foreground pt-1 mt-1 border-t text-center">
                {file.name}
              </div>
            </div>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default FileManager
